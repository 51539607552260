import React from "react";
import PreProject from "./PreProject/PreProject";
import PostProject from "./PostProject/PostProject";
import Payment from "./Payment/Payment";
import Tabs from "../../Common/Tabs/Tabs";
import ProgramLayout from "../../Common/ProgramLayout";
import LoaderComponent from "../../Common/Loader/Loader";
import Modal from "../../Common/Modal/Modal";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import { AuthContext } from "../../../firebaseAuthContext";
import ApplicationReview from "./ApplicationReview/ApplicationReview";
import CESApplicationReview from "./CESApplicationReview/CESApplicationReview";
import { applicationService } from "../../../services/applicationService";
import "./ReviewBoard.css";
import StatusLogs from "./StatusLogs/StatusLogs";
import ApplicationNotes from "./../ApplicationsBoard/Notes/Notes.js";
import AppFlagLogs from "./../ApplicationsBoard/AppFlagLogs/AppFlagLogs.js";
import FlagApplication from "../ApplicationsBoard/flagApplication/flagApplication";
import { utils } from "../../../helpers/utils";
import ModalAppDataComponent from "../../Common/ModalAppDetails/ModalAppData";
import CustomerApplications from "./../ApplicationsBoard/CustomerApplications/CustomerApplications";
import ReviewDocuments from "../ApplicationsBoard/ReviewDocuments/ReviewDocuments";
import ReviewBankingDetails from "./ReviewBankingDetails/ReviewBankingDetails";
import ViewChangeRequests from "../ApplicationsBoard/ViewChangeRequests/ViewChangeRequests";
import { debounce } from "debounce";
import SendCheque from "./../ApplicationsBoard/SendCheque/SendCheque";
import AppState from "../ApplicationsBoard/AppState/AppState";
import StartPrimaryReview from "./StartPrimaryReview/StartPrimaryReview";
import StartSecondaryReview from "./StartSecondaryReview/StartSecondaryReview";
import InvoiceReview from "./InvoiceReview/InvoiceReview";
import Table from "../../Common/Table/Table";

class ReviewBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      showFlagModal: false,
      activeAppData: [],
      activeAppId: "",
      showReviewBankingDetailsModal: false,
      showChangeRequestsModal: false,
      showReviewAppModal: false,
      showRequestInfoModal: false,
      showApprovedQAQCModal: false,
      showRejectAppModal: false,
      showStatusLogsModal: false,
      InformationRequested: "",
      rejectedAppReason: "",
      infoRequestedSuccessfully: false,
      appRejectedSuccessfully: false,
      confirmingIR: false,
      confirmingQAQC: false,
      showNotesModal: false,
      showFlagLogsModal: false,
      showAddFlagModal: false,
      confirmingRejection: false,
      applicationObj: {},
      application: {},
      appLoading: true,
      showViewCustomerApplications: false,
      showRetrofitInternalStatusLogsModal: false,
      showRetrofitStartPrimaryReviewModal: false,
      showRetrofitStartSecondaryReviewModal: false,
      showRetrofitInvoiceModal: false,
      selectedTab: "Pre-Project",
      showReviewDocumentsModal: false,
      showSendChequeModal: false,
      showAppStateModal: false,
      showIRCompletedModal: false,
      showEnervaGuidanceReceivedModal: false,
      showIESOExceptionDecisionModal: false,
      showApplicationRejectedAsIneligibleModal: false,
      showApplicationApprovedModal: false,
      showInvoiceApproveModal: false,
      showInvoiceEditModal: false,
      showIRCompletedMessage: false,
      showEGRMessage: false,
      showIESOEMessage: false,
      showRIMessage: false,
      showIRMessage: false,
      commonCDMISMessage: true,
      checklistData: [],
      rejectionDate: "",
      "Pre-ProjectFilters": {
        SelectedStatusFilters: [],
        StageFilters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 26, 27, 28, 29, 30, 31, 37, 38, 39],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
        onlyActiveApps: [true, false],
        stream: [],
      },
      "Post-ProjectFilters": {
        SelectedStatusFilters: [],
        StageFilters: [11, 12, 13, 14, 15, 16, 17, 18, 19],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
        onlyActiveApps: [true, false],
        stream: [],
      },
      PaymentFilters: {
        SelectedStatusFilters: [],
        StageFilters: [10, 20, 21, 22, 32, 33, 34, 35, 36, 40],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
    };
    this.searchDebouncedData = debounce(this.searchData, 450);
  }

  componentDidMount() {
    this.props.loadUserDetails();
    if (this.props.history.location.pathname.includes("retrofit")) {
      this.props.loadAdminConfigRetrofit();
    } else {
      this.props.loadAdminConfig();
    }
  }

  exportFacilityZip = async (stage, appId, facilityId, fileName) => {
    await applicationService.getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId, fileName);
  };

  showActionModal = (selectedapplicationData) => {
    this.setState({
      selectedapplicationData,
    });
    if (selectedapplicationData.modalType === "review-application") {
      this.props.loadAppMeasures(selectedapplicationData.id);
      applicationService.getApplication(selectedapplicationData.id).then((appObj) => {
        this.setState({
          showReviewAppModal: true,
          activeAppId: selectedapplicationData.id,
          activeAppData: appObj,
        });
      });
    } else if (selectedapplicationData.modalType === "information-requested") {
      applicationService.getApplication(selectedapplicationData.id).then((appObj) => {
        this.setState({
          applicationObj: appObj,
          appLoading: false,
        });
      });
      this.setState({
        showRequestInfoModal: true,
        InformationRequested: "",
        infoRequestedSuccessfully: false,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "approve-qaqc") {
      applicationService.getApplication(selectedapplicationData.id).then((appObj) => {
        this.setState({
          applicationObj: appObj,
          appLoading: false,
          activeAppId: selectedapplicationData.id,
        });
      });
      this.setState({
        showApprovedQAQCModal: true,
        InformationRequested: "",
        qaqcApprovedSuccessfully: false,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "reject-app") {
      applicationService.getApplication(selectedapplicationData.id).then((appObj) => {
        this.setState({
          applicationObj: appObj,
          appLoading: false,
        });
      });
      this.setState({
        showRejectAppModal: true,
        appRejectedSuccessfully: false,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-status-log") {
      this.props.getLogs(selectedapplicationData.id);
      this.setState({
        showStatusLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-notes") {
      const { adminUserInfo } = this.props;
      this.setState({
        showNotesModal: true,
        adminUserInfo: adminUserInfo,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "application-state") {
      this.setState({
        showAppStateModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "flag-application") {
      this.setState({
        showAddFlagModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "internal-status-logs") {
      this.setState({
        showRetrofitInternalStatusLogsModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "start-primary-review") {
      const { adminUserInfo } = this.props;
      this.setState({
        showRetrofitStartPrimaryReviewModal: true,
        activeAppId: selectedapplicationData.id,
        adminUserInfo: adminUserInfo,
      });
    } else if (selectedapplicationData.modalType === "start-secondary-review") {
      const { adminUserInfo } = this.props;
      this.setState({
        showRetrofitStartSecondaryReviewModal: true,
        activeAppId: selectedapplicationData.id,
        adminUserInfo: adminUserInfo,
      });
    } else if (selectedapplicationData.modalType === "invoice-review") {
      const { adminUserInfo } = this.props;
      this.setState({
        showRetrofitInvoiceModal: true,
        activeAppId: selectedapplicationData.id,
        adminUserInfo: adminUserInfo,
      });
    } else if (selectedapplicationData.modalType === "flag-logs-application") {
      this.props.getFlagLogs(selectedapplicationData.id);
      this.setState({
        showFlagLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-customer-applications") {
      //console.log("selected application----",selectedapplicationData);
      this.setState({
        showViewCustomerApplications: true,
        application: selectedapplicationData,
        activeCustomerId: selectedapplicationData.customerId,
        activeCustomerCategory: "customer",
      });
    } else if (selectedapplicationData.modalType === "review-documents") {
      //console.log("selected application----",selectedapplicationData);
      const { adminUserInfo } = this.props;
      this.setState({
        showReviewDocumentsModal: true,
        adminUserInfo: adminUserInfo,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "review-banking-details") {
      this.setState({
        showReviewBankingDetailsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-change-requests") {
      this.setState({
        showChangeRequestsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "send-cheque") {
      this.setState({
        showSendChequeModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "ir-completed") {
      this.setState({
        appLoading: false,
        showIRCompletedModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "enerva-guidance-received") {
      this.setState({
        appLoading: false,
        showEnervaGuidanceReceivedModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "ieso-exception-decision") {
      this.setState({
        appLoading: false,
        showIESOExceptionDecisionModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "application-rejected-as-ineligible") {
      applicationService.getRetrofitApplicationChecklist(selectedapplicationData.id).then(async (data) => {
        let stage = selectedapplicationData.stage;
        let appId = `${selectedapplicationData.id}`;
        let facilityZipUrlByStage = data[selectedapplicationData.stage];
        if (facilityZipUrlByStage) {
          for (var key in facilityZipUrlByStage) {
            let facilityId = Object.keys(facilityZipUrlByStage[key])[0];
            let finalChecklistZipObj = await applicationService.getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId);
            facilityZipUrlByStage[key][facilityId].finalZipURL = finalChecklistZipObj.resultZipUrl;
            facilityZipUrlByStage[key][facilityId].finalZipURLName = finalChecklistZipObj.name;
          }
          data[selectedapplicationData.stage] = facilityZipUrlByStage;
          this.setState({
            appLoading: false,
            showApplicationRejectedAsIneligibleModal: true,
            activeAppId: selectedapplicationData.id,
            application: selectedapplicationData,
            checklistData: data,
          });
        }
      });
      this.setState({
        appLoading: true,
        showApplicationRejectedAsIneligibleModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "application-approved") {
      applicationService.getRetrofitApplicationChecklist(selectedapplicationData.id).then(async (data) => {
        let stage = selectedapplicationData.stage;
        let appId = `${selectedapplicationData.id}`;
        let facilityZipUrlByStage = data[selectedapplicationData.stage];
        if (facilityZipUrlByStage) {
          for (var key in facilityZipUrlByStage) {
            let facilityId = Object.keys(facilityZipUrlByStage[key])[0];
            let finalChecklistZipObj = await applicationService.getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId);
            facilityZipUrlByStage[key][facilityId].finalZipURL = finalChecklistZipObj.resultZipUrl;
            facilityZipUrlByStage[key][facilityId].finalZipURLName = finalChecklistZipObj.name;
          }
          data[selectedapplicationData.stage] = facilityZipUrlByStage;
          this.setState({
            appLoading: false,
            showApplicationApprovedModal: true,
            activeAppId: selectedapplicationData.id,
            application: selectedapplicationData,
            checklistData: data,
          });
        }
      });
      this.setState({
        appLoading: true,
        showApplicationApprovedModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "invoice-approve") {
      this.setState({
        activeAppId: selectedapplicationData.id,
        showInvoiceApproveModal: true,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "invoice-edit") {
      this.setState({
        activeAppId: selectedapplicationData.id,
        showInvoiceEditModal: true,
        application: selectedapplicationData,
      });
    } else {
      // Do Nothing
    }
  };

  onDateChange = (name, value) => {
    this.setState({
      [name]: value,
    });
    // console.log(dateRange)
  };

  setFilters = (stage, newState) => {
    let updateObj = {};
    updateObj = {
      [`${stage}Filters`]: {
        ...this.state[`${stage}Filters`],
        ...newState,
      },
    };
    this.setState(updateObj, () => {
      this.searchData();
    });
  };

  searchData = () => {
    let stage = this.state.selectedTab;
    const { sortConfig, StageFilters, SelectedStatusFilters, SelectedFlagFilters, searchInput, onlyActiveApps, stream } =
      this.state[`${stage}Filters`];
    let config = {
      statuses: SelectedStatusFilters,
      flags: SelectedFlagFilters,
      stage: StageFilters,
      sort: sortConfig,
      reviewerId: this.context.user.uid,
      activeApps: onlyActiveApps,
      program: this.props.currentProgram,
      stream: stream,
    };
    if (this.props.currentProgram === "retrofit") {
      this.props.getApplicationsToReviewRetrofit(stage, searchInput, config, 0);
    } else {
      this.props.getApplicationsToReview(stage, searchInput, config, 0);
    }
  };

  closeActionModal = (reload) => {
    this.setState(
      {
        showReviewAppModal: false,
        showRequestInfoModal: false,
        showApprovedQAQCModal: false,
        showStatusLogsModal: false,
        showRejectAppModal: false,
        confirmingIR: false,
        confirmingQAQC: false,
        confirmingRejection: false,
        InformationRequested: "",
        rejectedAppReason: "",
        rejectionDate: "",
        showNotesModal: false,
        showAddNoteModal: false,
        infoRequestedSuccessfully: false,
        appRejectedSuccessfully: false,
        qaqcApprovedSuccessfully: false,
        showAddFlagModal: false,
        showFlagLogsModal: false,
        showViewCustomerApplications: false,
        showReviewDocumentsModal: false,
        showReviewBankingDetailsModal: false,
        showChangeRequestsModal: false,
        showSendChequeModal: false,
        showAppStateModal: false,
        showRetrofitInternalStatusLogsModal: false,
        showRetrofitStartPrimaryReviewModal: false,
        showRetrofitStartSecondaryReviewModal: false,
        showRetrofitInvoiceModal: false,
        showIRCompletedModal: false,
        showEnervaGuidanceReceivedModal: false,
        showIESOExceptionDecisionModal: false,
        showApplicationRejectedAsIneligibleModal: false,
        showApplicationApprovedModal: false,
        showInvoiceApproveModal: false,
        showInvoiceEditModal: false,
        commonCDMISMessage: true,
        showIRCompletedMessage: false,
      },
      () => {
        if (reload) {
          this.searchData();
        }
      }
    );
  };
  onTabChange = (selectedTab) => {
    this.setState(
      {
        selectedTab,
      },
      () => {
        this.searchData();
      }
    );
  };

  handleRequestInfoChange = (name, value) => {
    this.setState({
      InformationRequested: value,
    });
  };

  handleRejectReasonChange = (name, value) => {
    this.setState({
      rejectedAppReason: value,
    });
  };

  confirmRequestInformation = () => {
    this.setState({
      confirmingIR: !this.state.confirmingIR,
    });
  };

  confirmQAQC = () => {
    this.setState({
      confirmingQAQC: !this.state.confirmingQAQC,
    });
  };

  confirmRejectApp = () => {
    this.setState({
      confirmingRejection: !this.state.confirmingRejection,
    });
  };

  approveQAQC = () => {
    let { InformationRequested } = this.state;
    let { adminUserInfo } = this.props;

    applicationService
      .approveQAQC({
        actionTaker: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        actionNote: InformationRequested,
        applicationId: this.state.activeAppId,
      })
      .then((res) => {
        this.setState(
          {
            qaqcApprovedSuccessfully: true,
          },
          () => {
            setTimeout(() => {
              this.closeActionModal(true);
            }, 800);
          }
        );
      });
  };

  rejectApplication = () => {
    let { rejectedAppReason, selectedTab } = this.state;

    let { adminUserInfo } = this.props;
    if (rejectedAppReason && selectedTab !== "Post-Project") {
      applicationService
        .createApplicationReview(this.state.activeAppId, {
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          reviewStatus: "Application Rejected",
          informationRequest: rejectedAppReason,
        })
        .then((res) => {
          this.setState(
            {
              appRejectedSuccessfully: true,
              rejectedAppReason: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 1000);
            }
          );
        });
    } else if (rejectedAppReason && selectedTab === "Post-Project") {
      applicationService
        .createPostProjectApplicationReview(this.state.activeAppId, {
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          reviewStatus: "Application Rejected",
          informationRequest: rejectedAppReason,
        })
        .then((res) => {
          this.setState(
            {
              appRejectedSuccessfully: true,
              rejectedAppReason: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 1000);
            }
          );
        });
    }
  };

  saveRequestInformation = () => {
    let { InformationRequested, selectedTab, applicationObj } = this.state;
    let { adminUserInfo } = this.props;
    if (InformationRequested && selectedTab !== "Post-Project") {
      applicationService
        .createApplicationReview(this.state.activeAppId, {
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          reviewStatus: "Application Information Requested",
          informationRequest: InformationRequested,
        })
        .then((res) => {
          this.setState(
            {
              infoRequestedSuccessfully: true,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 1000);
            }
          );
        });
    } else if (InformationRequested && selectedTab === "Post-Project") {
      let updateObj = {
        reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        reviewStatus: "Application Information Requested",
        informationRequest: InformationRequested,
      };
      if (applicationObj.status === "QA/QC In Progress") {
        updateObj.qaQCInProgress = true;
      }
      applicationService.createPostProjectApplicationReview(this.state.activeAppId, updateObj).then((res) => {
        this.setState(
          {
            infoRequestedSuccessfully: true,
            InformationRequested: "",
          },
          () => {
            setTimeout(() => {
              this.closeActionModal(true);
            }, 1000);
          }
        );
      });
    }
  };

  approveIRCompleted = () => {
    let { InformationRequested, selectedTab } = this.state;
    let { adminUserInfo } = this.props;

    if (InformationRequested) {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: selectedTab,
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "IR Completed",
        })
        .then((res) => {
          this.setState(
            {
              showIRCompletedMessage: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };

  enervaGuidanceReceivedReview = () => {
    let { InformationRequested, selectedTab } = this.state;
    let { adminUserInfo } = this.props;
    if (InformationRequested) {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: selectedTab,
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "Enerva Guidance Received",
        })
        .then((res) => {
          this.setState(
            {
              showEGRMessage: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };

  iESOExceptionDecisionReview = () => {
    let { InformationRequested, selectedTab } = this.state;
    let { adminUserInfo } = this.props;
    if (InformationRequested) {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: selectedTab,
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "IESO Exception Decision",
        })
        .then((res) => {
          this.setState(
            {
              showIESOEMessage: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };

  applicationRejectedIneligibleReview = () => {
    let { InformationRequested, rejectionDate, selectedapplicationData, selectedTab } = this.state;
    let { adminUserInfo } = this.props;
    let updateObj = {
      stage: selectedTab,
      reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
      informationRequestNote: InformationRequested,
    };
    if (selectedapplicationData && selectedapplicationData.flag && selectedapplicationData.flag.status === "Reject Application") {
      updateObj.rejectionDeterminationDate = rejectionDate;
      updateObj.reviewStatus = "Application Rejected as Ineligible";
    } else if (selectedapplicationData && selectedapplicationData.flag && selectedapplicationData.flag.status === "Cancelled Application") {
      updateObj.cancellationDeterminationDate = rejectionDate;
      updateObj.reviewStatus = `${selectedapplicationData.stage} Application Cancelled`;
    } else if (selectedapplicationData && selectedapplicationData.flag && selectedapplicationData.flag.status === "Withdrawn Application") {
      updateObj.withdrawlRequestDate = rejectionDate;
      updateObj.reviewStatus = `${selectedapplicationData.stage} Application Withdrawn`;
    }
    applicationService.retrofitCompleteReview(this.state.activeAppId, updateObj).then((res) => {
      this.setState(
        {
          showRIMessage: false,
          rejectionDate: "",
          InformationRequested: "",
          commonCDMISMessage: "",
        },
        () => {
          setTimeout(() => {
            this.closeActionModal(true);
          }, 800);
        }
      );
    });
  };

  applicationApprovedReview = () => {
    let { InformationRequested, selectedTab } = this.state;
    let { adminUserInfo } = this.props;
    if (InformationRequested && selectedTab === "Pre-Project") {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: selectedTab,
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "Application Pre-Approved",
        })
        .then((res) => {
          this.setState(
            {
              showIRMessage: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    } else if (InformationRequested && selectedTab === "Post-Project") {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: selectedTab,
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "Application Approved",
        })
        .then((res) => {
          this.setState(
            {
              showIRMessage: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };
  approveInvoice = () => {
    let { InformationRequested } = this.state;
    let { adminUserInfo } = this.props;

    if (InformationRequested) {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: "Payment",
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "Invoice Approved",
        })
        .then((res) => {
          this.setState(
            {
              showInvoiceApproveModal: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };

  invoiceEditCompleted = () => {
    let { InformationRequested } = this.state;
    let { adminUserInfo } = this.props;

    if (InformationRequested) {
      applicationService
        .retrofitCompleteReview(this.state.activeAppId, {
          stage: "Payment",
          reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          informationRequestNote: InformationRequested,
          reviewStatus: "Invoice Assigned for Review",
        })
        .then((res) => {
          this.setState(
            {
              showInvoiceEditModal: false,
              InformationRequested: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 800);
            }
          );
        });
    }
  };

  render() {
    const { displayName } = this.context.user;
    const {
      flagLogs,
      applicationsByStageLoading,
      activeStatusLogs,
      activeChangeRequests,
      activeChangeReqLogs,
      appStatusLogsLoading,
      appNotesLoading,
      currentProgram,
      appFlagsLoading,
      configData,
    } = this.props;
    const {
      showStatusLogsModal,
      showReviewAppModal,
      showRequestInfoModal,
      showApprovedQAQCModal,
      InformationRequested,
      rejectedAppReason,
      infoRequestedSuccessfully,
      qaqcApprovedSuccessfully,
      appRejectedSuccessfully,
      confirmingIR,
      confirmingQAQC,
      confirmingRejection,
      showRejectAppModal,
      showNotesModal,
      showAddFlagModal,
      showFlagLogsModal,
      applicationObj,
      appLoading,
      showViewCustomerApplications,
      showReviewDocumentsModal,
      showReviewBankingDetailsModal,
      showChangeRequestsModal,
      showSendChequeModal,
      showAppStateModal,
      showRetrofitInternalStatusLogsModal,
      showRetrofitStartPrimaryReviewModal,
      showRetrofitStartSecondaryReviewModal,
      showRetrofitInvoiceModal,
      selectedapplicationData,
      showIRCompletedModal,
      showEnervaGuidanceReceivedModal,
      showIESOExceptionDecisionModal,
      showApplicationRejectedAsIneligibleModal,
      showApplicationApprovedModal,
      showInvoiceApproveModal,
      showInvoiceEditModal,
      showIRCompletedMessage,
      showEGRMessage,
      showIESOEMessage,
      showRIMessage,
      showIRMessage,
      commonCDMISMessage,
      rejectionDate,
      checklistData,
      activeAppData
    } = this.state;
    let downloadingFileRows = [];
    if (!utils.isNullOrEmpty(checklistData) && selectedapplicationData && selectedapplicationData.stage === "Pre-Project") {
      downloadingFileRows = Object.values(checklistData && checklistData["Pre-Project"]).map((data, i) => {
        let newData = Object.values(data);
        return {
          serialNo: i + 1,
          stage: "Pre-Project",
          appId: `${selectedapplicationData.id}`,
          facilityId: newData[0].facilityId,
          facilityAdd: newData[0].facilityAddress,
          url: newData[0].checklistZipUrl,
          documentDownloadAPI: newData[0].name,
          finalChecklistZipUrl: newData[0].finalZipURL,
          finalChecklistZipName: newData[0].finalZipURLName,
          projectType:newData[0].projectType
        };
      });
    } else if (!utils.isNullOrEmpty(checklistData) && selectedapplicationData && selectedapplicationData.stage === "Post-Project") {
      downloadingFileRows = Object.values(checklistData && checklistData["Post-Project"]).map((data, i) => {
        let newData = Object.values(data);
        return {
          serialNo: i + 1,
          stage: "Post-Project",
          appId: `${selectedapplicationData.id}`,
          facilityId: newData[0].facilityId,
          facilityAdd: newData[0].facilityAddress,
          url: newData[0].checklistZipUrl,
          documentDownloadAPI: newData[0].name,
          finalChecklistZipUrl: newData[0].finalZipURL,
          finalChecklistZipName: newData[0].finalZipURLName,
          projectType:newData[0].projectType
        };
      });
    }

    return (
      <ProgramLayout showFooter={false} props={this.props}>
        <React.Fragment>
          {/* The below modal is for Requesting Information  */}
          <Modal modalSize={"large"} className={"esb-modal"} IsOpen={showRequestInfoModal} handleCloseModal={() => this.closeActionModal()}>
            {infoRequestedSuccessfully ? (
              <p>Information requested successfully!</p>
            ) : (
              <React.Fragment>
                {appLoading ? (
                  <LoaderComponent />
                ) : (
                  <React.Fragment>
                    <div className="appinfoblock">
                      <ModalAppDataComponent applicationObj={applicationObj} modalTitle={`Request Information`}></ModalAppDataComponent>
                    </div>
                    <div className="information-container">
                      <div className="information-container-section">
                        <Input
                          disabled={confirmingIR}
                          type={"richtext"}
                          value={InformationRequested}
                          onChange={this.handleRequestInfoChange}
                          name="InformationRequested"
                        />
                      </div>
                    </div>
                    <div className="button-set">
                      {confirmingIR ? (
                        <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmRequestInformation} />
                      ) : (
                        <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                      )}
                      {confirmingIR ? (
                        <Button
                          className="signup-btn"
                          title={"Submit IR"}
                          uppercase
                          onClick={this.saveRequestInformation}
                          disabled={!InformationRequested}
                        />
                      ) : (
                        <Button
                          className="signup-btn"
                          title={"Confirm IR"}
                          uppercase
                          onClick={this.confirmRequestInformation}
                          disabled={!utils.cleanHTMLText(InformationRequested)}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Modal>
          <Modal
            IsOpen={showViewCustomerApplications}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appStatusLogsLoading}
          >
            <CustomerApplications
              customerId={this.state.activeCustomerId}
              customerCategory={this.state.activeCustomerCategory}
              closeActionModal={this.closeActionModal}
              application={this.state.application}
            ></CustomerApplications>
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showRetrofitInternalStatusLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <AppFlagLogs
              appFlagsLoading={appFlagsLoading}
              FlagLogs={flagLogs}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
              application={this.state.application}
              applicationId={this.state.activeAppId}
            />
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showApprovedQAQCModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            {qaqcApprovedSuccessfully ? (
              <p>QA/QC Approved Successfully!</p>
            ) : (
              <React.Fragment>
                {appLoading ? (
                  <LoaderComponent />
                ) : (
                  <React.Fragment>
                    <div className="appinfoblock">
                      <ModalAppDataComponent applicationObj={applicationObj} modalTitle={`Approve QA/QC`}></ModalAppDataComponent>
                    </div>
                    <div className="information-container">
                      <div className="information-container-section">
                        <Input
                          disabled={confirmingQAQC}
                          type={"richtext"}
                          value={InformationRequested}
                          onChange={this.handleRequestInfoChange}
                          name="qaqc text"
                        />
                      </div>
                    </div>
                    <div className="button-set">
                      {confirmingQAQC ? (
                        <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmQAQC} />
                      ) : (
                        <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                      )}
                      {confirmingQAQC ? (
                        <Button
                          className="signup-btn"
                          title={"Approve QA/QC"}
                          uppercase
                          onClick={this.approveQAQC}
                          disabled={!InformationRequested}
                        />
                      ) : (
                        <Button
                          className="signup-btn"
                          title={"Confirm QA/QC"}
                          uppercase
                          onClick={this.confirmQAQC}
                          disabled={!utils.cleanHTMLText(InformationRequested)}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Modal>
          <Modal className={"esb-modal"} IsOpen={showRejectAppModal} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            {appRejectedSuccessfully ? (
              <p>Application rejected successfully!</p>
            ) : (
              <React.Fragment>
                {appLoading ? (
                  <LoaderComponent />
                ) : (
                  <React.Fragment>
                    <div className="appinfoblock">
                      <ModalAppDataComponent applicationObj={applicationObj} modalTitle={`Reason for Rejection`}></ModalAppDataComponent>
                    </div>
                    <div className="information-container">
                      <div className="information-container-section">
                        <Input
                          type="richtext"
                          disabled={confirmingRejection}
                          value={rejectedAppReason}
                          onChange={this.handleRejectReasonChange}
                          name="rejectedAppReason"
                        />
                      </div>
                    </div>
                    <div className="button-set">
                      {confirmingRejection ? (
                        <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmRejectApp} />
                      ) : (
                        <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                      )}
                      {confirmingRejection ? (
                        <Button
                          className="signup-btn"
                          title={"Reject Application"}
                          uppercase
                          onClick={this.rejectApplication}
                          disabled={!rejectedAppReason}
                        />
                      ) : (
                        <Button
                          className="signup-btn"
                          title={"Confirm Rejection"}
                          uppercase
                          onClick={this.confirmRejectApp}
                          disabled={!utils.cleanHTMLText(rejectedAppReason)}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showNotesModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ApplicationNotes
              application={this.state.application}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
            />
          </Modal>
          <Modal
            IsOpen={showRetrofitStartPrimaryReviewModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <StartPrimaryReview
              application={selectedapplicationData}
              configData={configData}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
              closeActionModal={(reload) => this.closeActionModal(reload)}
            />
          </Modal>
          <Modal
            IsOpen={showRetrofitStartSecondaryReviewModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <StartSecondaryReview
              application={selectedapplicationData}
              configData={configData}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
              closeActionModal={(reload) => this.closeActionModal(reload)}
            />
          </Modal>
          <Modal
            IsOpen={showRetrofitInvoiceModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <InvoiceReview
              application={selectedapplicationData}
              configData={configData}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
              closeActionModal={(reload) => this.closeActionModal(reload)}
            />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showReviewDocumentsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ReviewDocuments
              application={this.state.application}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
            />
          </Modal>
          <Modal IsOpen={showAppStateModal} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            <AppState
              applicationId={this.state.activeAppId}
              reload={this.searchData}
              closeActionModal={(reload) => this.closeActionModal(reload)}
            />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showFlagLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            loading={appNotesLoading}
            applicationId={this.state.applicationId}
          >
            <AppFlagLogs
              applicationId={this.state.activeAppId}
              FlagLogs={flagLogs}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
            />
          </Modal>
          <Modal modalSize={"large"} className={"esb-modal"} IsOpen={showAddFlagModal}>
            <FlagApplication
              applicationId={this.state.activeAppId}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
            ></FlagApplication>
          </Modal>
          <Modal
            IsOpen={showReviewAppModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            loading={this.props.applicationMeasuresLoading}
            modalSize={activeAppData.length ? "" : activeAppData.stream === "ces" ? "fullscreen" : "large"}
            applicationId={this.state.applicationId}
          >
            {activeAppData.length ? "" : activeAppData.stream === "ces" ? (
              <CESApplicationReview
                appData={this.state.activeAppData}
                measureList={this.props.measureList}
                selectedAppId={this.state.activeAppId}
                closeActionModal={this.closeActionModal}
                reviewer={displayName}
              />
            ) : (
              <ApplicationReview
                appData={this.state.activeAppData}
                measureList={this.props.measureList}
                selectedAppId={this.state.activeAppId}
                closeActionModal={this.closeActionModal}
                reviewer={displayName}
              />
            )}
          </Modal>
          <Modal
            IsOpen={showReviewBankingDetailsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ReviewBankingDetails
              closeActionModal={this.closeActionModal}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.props.adminUserInfo}
            />
          </Modal>
          <Modal
            IsOpen={showChangeRequestsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ViewChangeRequests
              applicationId={this.state.activeAppId}
              activeChangeRequests={activeChangeRequests}
              activeChangeReqLogs={activeChangeReqLogs}
            />
          </Modal>
          <Modal IsOpen={showSendChequeModal} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            <SendCheque applicationId={this.state.activeAppId} closeActionModal={(reload) => this.closeActionModal(reload)} />
          </Modal>
          <Modal
            //  HeaderText={'Status Logs'}
            IsOpen={showStatusLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            loading={appStatusLogsLoading}
            modalSize={"large"}
          >
            <StatusLogs applicationId={this.state.activeAppId} activeStatusLogs={activeStatusLogs} />
          </Modal>
          <Modal
            modalSize={"large"}
            loading={appLoading}
            className={"esb-modal"}
            IsOpen={showIRCompletedModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.activeAppId}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "IR Completed" }}></h2>
              </div>
            </div>
            {showIRCompletedMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark the review for this application as <span>IR Completed</span>. Please verify the accuracy of the
                    note before confirming the review.
                  </p>
                </div>
                <div className="button-set">
                  <Button
                    className="inversed-btn"
                    title={"BACK"}
                    uppercase
                    onClick={() => this.setState({ showIRCompletedMessage: false })}
                  />
                  <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.approveIRCompleted} />
                </div>
              </>
            ) : (
              <>
                <div className="information-container">
                  <div className="information-container-section">
                    <Input
                      disabled={confirmingIR}
                      type={"richtext"}
                      value={InformationRequested}
                      onChange={this.handleRequestInfoChange}
                      name="IRCompletedReview"
                    />
                  </div>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  <Button
                    className="signup-btn"
                    title={"Complete Review"}
                    uppercase
                    onClick={() => this.setState({ showIRCompletedMessage: true })}
                    disabled={!utils.cleanHTMLText(InformationRequested)}
                  />
                </div>
              </>
            )}
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showEnervaGuidanceReceivedModal}
            handleCloseModal={() => this.closeActionModal()}
            loading={appLoading}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.activeAppId}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Enerva Guidance Received" }}></h2>
              </div>
            </div>
            {showEGRMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark the review for this application as <span>Enerva Guidance Received</span>. Please verify the
                    accuracy of the note before confirming the review.
                  </p>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showEGRMessage: false })} />
                  <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.enervaGuidanceReceivedReview} />
                </div>
              </>
            ) : (
              <>
                <div className="information-container">
                  <div className="information-container-section">
                    <Input
                      disabled={confirmingIR}
                      type={"richtext"}
                      value={InformationRequested}
                      onChange={this.handleRequestInfoChange}
                      name="EnervaGuidanceReceived"
                    />
                  </div>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  <Button
                    className="signup-btn"
                    title={"Complete Review"}
                    uppercase
                    onClick={() => this.setState({ showEGRMessage: true })}
                    disabled={!utils.cleanHTMLText(InformationRequested)}
                  />
                </div>
              </>
            )}
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showIESOExceptionDecisionModal}
            handleCloseModal={() => this.closeActionModal()}
            loading={appLoading}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.activeAppId}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "IESO Exception Decision" }}></h2>
              </div>
            </div>
            {showIESOEMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark this application as <span>Decision Made for Exception by IESO</span>. Please verify the accuracy
                    of the note before confirming the review.
                  </p>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showIESOEMessage: false })} />
                  <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.iESOExceptionDecisionReview} />
                </div>
              </>
            ) : (
              <>
                <div className="information-container">
                  <div className="information-container-section">
                    <Input
                      disabled={confirmingIR}
                      type={"richtext"}
                      value={InformationRequested}
                      onChange={this.handleRequestInfoChange}
                      name="IESOExceptionDecision"
                    />
                  </div>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  <Button
                    className="signup-btn"
                    title={"Complete Review"}
                    uppercase
                    onClick={() => this.setState({ showIESOEMessage: true })}
                    disabled={!utils.cleanHTMLText(InformationRequested)}
                  />
                </div>
              </>
            )}
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showApplicationRejectedAsIneligibleModal}
            handleCloseModal={() => this.closeActionModal()}
            loading={appLoading}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.activeAppId}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              {selectedapplicationData && selectedapplicationData.flag && selectedapplicationData.flag.status === "Reject Application" && (
                <div className="section-below-header">
                  <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "APPLICATION REJECTED AS INELIGIBLE" }}></h2>
                </div>
              )}
              {selectedapplicationData &&
                selectedapplicationData.flag &&
                selectedapplicationData.flag.status === "Withdrawn Application" && (
                  <div className="section-below-header">
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application Withdrawn" }}></h2>
                  </div>
                )}
              {selectedapplicationData &&
                selectedapplicationData.flag &&
                selectedapplicationData.flag.status === "Cancelled Application" && (
                  <div className="section-below-header">
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application Cancelled" }}></h2>
                  </div>
                )}
            </div>
            {showRIMessage ? (
              selectedapplicationData && selectedapplicationData.flag && selectedapplicationData.flag.status === "Reject Application" ? (
                <>
                  <div className="final-message-container">
                    <p>
                      You are about to mark the review for this application as <span>Application Rejected as Ineligible</span>. This might
                      send out an email to the applicants &amp; applicant reps. Please verify the accuracy of the information and the note.
                    </p>
                  </div>
                  <div className="button-set">
                    <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showRIMessage: false })} />
                    <Button
                      className="signup-btn"
                      title={
                        selectedapplicationData &&
                        selectedapplicationData.flag &&
                        selectedapplicationData.flag.status === "Reject Application"
                          ? "MARK AS REJECTED"
                          : "CONFIRM REVIEW"
                      }
                      uppercase
                      onClick={this.applicationRejectedIneligibleReview}
                    />
                  </div>
                </>
              ) : selectedapplicationData &&
                selectedapplicationData.flag &&
                selectedapplicationData.flag.status === "Withdrawn Application" ? (
                <>
                  <div className="final-message-container">
                    <p>
                      You are about to mark the review for this application as <span>Application Withdrawn</span>. This might send out an
                      email to the applicants &amp; applicant reps. Please verify the accuracy of the information and the note.
                    </p>
                  </div>
                  <div className="button-set">
                    <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showRIMessage: false })} />
                    <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.applicationRejectedIneligibleReview} />
                  </div>
                </>
              ) : selectedapplicationData &&
                selectedapplicationData.flag &&
                selectedapplicationData.flag.status === "Cancelled Application" ? (
                <>
                  <div className="final-message-container">
                    <p>
                      You are about to mark the review for this application as <span>Application Cancelled</span>. This might send out an
                      email to the applicants &amp; applicant reps. Please verify the accuracy of the information and the note.
                    </p>
                  </div>
                  <div className="button-set">
                    <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showRIMessage: false })} />
                    <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.applicationRejectedIneligibleReview} />
                  </div>
                </>
              ) : (
                ""
              )
            ) : (
              selectedapplicationData && (
                <React.Fragment>
                  <div className="information-container ri-container">
                    {selectedapplicationData.flag && selectedapplicationData.flag.status === "Reject Application" && (
                      <Table
                        rows={downloadingFileRows}
                        headings={[
                          { title: "Sr.No.", value: "serialNo" },
                          { title: "Facility ID", value: "facilityId" },
                          { title: "Facility Address", value: "facilityAdd" },
                          { title: "Download Facility Files", value: "documentDownloadAPI" },
                          {title:  "Project Type", value:"projectType"}
                        ]}
                      />
                    )}
                    <div className="rejection-date">
                      <div className="rejection-date-picker">
                        <Input
                          label={
                            selectedapplicationData &&
                            selectedapplicationData.flag &&
                            selectedapplicationData.flag.status === "Reject Application"
                              ? "Rejection Determination Date"
                              : selectedapplicationData.flag && selectedapplicationData.flag.status === "Withdrawn Application"
                              ? "Withdraw Request Date"
                              : "Cancellation Determination Date"
                          }
                          name="rejectionDate"
                          value={rejectionDate}
                          max={new Date()}
                          type="date"
                          onChange={this.onDateChange}
                        />
                      </div>
                    </div>
                    <div className="information-container-section">
                      <Input
                        disabled={confirmingIR}
                        type={"richtext"}
                        value={InformationRequested}
                        onChange={this.handleRequestInfoChange}
                        name="ApplicationRejectedAsIneligible"
                      />
                    </div>
                  </div>
                  <div className="button-set">
                    <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                    <Button
                      className="signup-btn"
                      title={
                        selectedapplicationData &&
                        selectedapplicationData.flag &&
                        selectedapplicationData.flag.status === "Reject Application"
                          ? "Reject Application"
                          : selectedapplicationData.flag && selectedapplicationData.flag.status === "Withdrawn Application"
                          ? "Withdraw Application"
                          : "Cancel Application"
                      }
                      uppercase
                      onClick={() => this.setState({ showRIMessage: true })}
                      disabled={!utils.cleanHTMLText(InformationRequested) && utils.isNullOrEmpty(rejectionDate)}
                    />
                  </div>
                </React.Fragment>
              )
            )}
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showApplicationApprovedModal}
            handleCloseModal={() => this.closeActionModal()}
            loading={appLoading}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.activeAppId}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">
                  {utils.formatAsCAD(
                    this.state.application.stage === "Post-Project"
                      ? this.state.application.actualIncentive
                      : this.state.application.estimatedIncentive
                  )}
                </div>
              </div>
              <div className="section-below-header">
                <h2
                  className="section-title"
                  dangerouslySetInnerHTML={{
                    __html: `${this.state.application.stage === "Pre-Project" ? "MARK AS PRE-APPROVED" : "MARK AS APPROVED"}`,
                  }}
                ></h2>
              </div>
            </div>
            {showIRMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark the review for this application as <span>Application Pre-Approved</span>. This might send out an
                    email to the applicants &amp; applicant reps. Please verify the accuracy of the information and the note.
                  </p>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"BACK"} uppercase onClick={() => this.setState({ showIRMessage: false })} />
                  <Button
                    className="signup-btn"
                    title={this.state.application.stage === "Pre-Project" ? "MARK AS PRE-APPROVED" : "MARK AS APPROVED"}
                    uppercase
                    onClick={this.applicationApprovedReview}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="information-container ri-container">
                  <p>
                    Please make sure you download the facility files in the table and upload them to ‘Document Upload’ section of their
                    respective Facility Site Review within the CDMIS Portal
                  </p>
                  {downloadingFileRows.length > 0 && (
                    <Table
                      rows={downloadingFileRows}
                      headings={[
                        { title: "Sr.No.", value: "serialNo" },
                        { title: "Facility ID", value: "facilityId" },
                        { title: "Facility Address", value: "facilityAdd" },
                        { title: "Download Facility Files", value: "documentDownloadAPI" },
                        {title:  "Project Type", value:"projectType"}
                      ]}
                    />
                  )}
                  <div className="information-container-section">
                    <Input
                      disabled={confirmingIR}
                      type={"richtext"}
                      value={InformationRequested}
                      onChange={this.handleRequestInfoChange}
                      name="ApplicationApprove"
                    />
                  </div>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  <Button
                    className="signup-btn"
                    title={"Complete Review"}
                    uppercase
                    onClick={() => this.setState({ showIRMessage: true })}
                    disabled={!utils.cleanHTMLText(InformationRequested)}
                  />
                </div>
              </>
            )}
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={showInvoiceApproveModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.application.id}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Mark as Invoice Approved" }}></h2>
              </div>
            </div>
            {showIRCompletedMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark the review for this application as Invoice Approved. Please verify the accuracy of the information
                    and the note.
                  </p>
                </div>
                <div className="button-set">
                  <Button
                    className="inversed-btn"
                    title={"BACK"}
                    uppercase
                    onClick={() => this.setState({ showIRCompletedMessage: false })}
                  />
                  <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.approveInvoice} />
                </div>
              </>
            ) : (
              <>
                {commonCDMISMessage ? (
                  <div className="information-container ri-container">
                    <p>Please login to the CDMIS portal and manually mark the application as Invoice Approved </p>
                  </div>
                ) : (
                  <div className="information-container">
                    <div className="information-container-section">
                      <Input
                        disabled={confirmingIR}
                        type={"richtext"}
                        value={InformationRequested}
                        onChange={this.handleRequestInfoChange}
                        name="IRCompletedReview"
                      />
                    </div>
                  </div>
                )}
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  {commonCDMISMessage ? (
                    <Button title={"Start"} uppercase onClick={() => this.setState({ commonCDMISMessage: false })} />
                  ) : (
                    <Button
                      className="signup-btn"
                      title={"Complete Review"}
                      uppercase
                      onClick={() => this.setState({ showIRCompletedMessage: true })}
                      disabled={!utils.cleanHTMLText(InformationRequested)}
                    />
                  )}
                </div>
              </>
            )}
          </Modal>
          <Modal modalSize={"large"} className={"esb-modal"} IsOpen={showInvoiceEditModal} handleCloseModal={() => this.closeActionModal()}>
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{this.state.application.id}</div>
                <div className="section-top-details name-section">{this.state.application.applicantName}</div>
                <div className="section-top-details">{utils.formatAsCAD(this.state.application.estimatedIncentive)}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Invoice Edits Completed" }}></h2>
              </div>
            </div>
            {showIRCompletedMessage ? (
              <>
                <div className="final-message-container">
                  <p>
                    You are about to mark the review for this application as Invoice Edits Completed. Please verify the accuracy of the note
                    before confirming the review.
                  </p>
                </div>
                <div className="button-set">
                  <Button
                    className="inversed-btn"
                    title={"BACK"}
                    uppercase
                    onClick={() => this.setState({ showIRCompletedMessage: false })}
                  />
                  <Button className="signup-btn" title={"CONFIRM REVIEW"} uppercase onClick={this.invoiceEditCompleted} />
                </div>
              </>
            ) : (
              <>
                <div className="information-container">
                  <div className="information-container-section">
                    <Input
                      disabled={confirmingIR}
                      type={"richtext"}
                      value={InformationRequested}
                      onChange={this.handleRequestInfoChange}
                      name="IRCompletedReview"
                    />
                  </div>
                </div>
                <div className="button-set">
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                  <Button
                    className="signup-btn"
                    title={"Complete Review"}
                    uppercase
                    onClick={() => this.setState({ showIRCompletedMessage: true })}
                    disabled={!utils.cleanHTMLText(InformationRequested)}
                  />
                </div>
              </>
            )}
          </Modal>
        </React.Fragment>
        {applicationsByStageLoading ? (
          <LoaderComponent>
            <h3>
              Welcome, <span className="highlight-text">{displayName}</span>
            </h3>
            <h3>Please wait while the page loads..</h3>
          </LoaderComponent>
        ) : null}
        <React.Fragment>
          <Tabs onTabChange={this.onTabChange}>
            <div label="Pre-Project">
              <PreProject
                SelectedStatusFilters={this.state["Pre-ProjectFilters"].SelectedStatusFilters}
                SelectedFlagFilters={this.state["Pre-ProjectFilters"].SelectedFlagFilters}
                StageFilters={this.state["Pre-ProjectFilters"].StageFilters}
                searchInput={this.state["Pre-ProjectFilters"].searchInput}
                sortConfig={this.state["Pre-ProjectFilters"].sortConfig}
                onlyActiveApps={this.state["Pre-ProjectFilters"].onlyActiveApps}
                setFilters={this.setFilters}
                reviewerId={this.context.user.uid}
                showActionModal={this.showActionModal}
              />
            </div>
            {/* <div label="Pre-Approval">
              {reviewAppList ?
                <PreApprovalNotice
                  appList={reviewAppList || []}
                  showActionModal={this.showActionModal}
                /> : ''}
            </div> */}
            <div label="Post-Project">
              <PostProject
                SelectedStatusFilters={this.state["Post-ProjectFilters"].SelectedStatusFilters}
                SelectedFlagFilters={this.state["Post-ProjectFilters"].SelectedFlagFilters}
                StageFilters={this.state["Post-ProjectFilters"].StageFilters}
                searchInput={this.state["Post-ProjectFilters"].searchInput}
                sortConfig={this.state["Post-ProjectFilters"].sortConfig}
                onlyActiveApps={this.state["Post-ProjectFilters"].onlyActiveApps}
                setFilters={this.setFilters}
                reviewerId={this.context.user.uid}
                showActionModal={this.showActionModal}
              />
            </div>
            <div label="Payment">
              <Payment
                SelectedFlagFilters={this.state["PaymentFilters"].SelectedFlagFilters}
                searchInput={this.state["PaymentFilters"].searchInput}
                StageFilters={this.state["PaymentFilters"].StageFilters}
                sortConfig={this.state["PaymentFilters"].sortConfig}
                setFilters={this.setFilters}
                reviewerId={this.context.user.uid}
                showActionModal={this.showActionModal}
              />
            </div>
          </Tabs>
        </React.Fragment>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { configData } = state.settings;
  const {
    reviewAppList,
    reviewAppListOffset,
    applicationsToReviewLoading,
    activeChangeRequests,
    activeChangeReqLogs,
    appStatusLogsLoading,
    activeStatusLogs,
    measureList,
    applicationMeasuresLoading,
    appNotesLoading,
    flagLogs,
    appFlagsLoading,
  } = state.application;
  return {
    currentProgram,
    adminUserInfo,
    reviewAppList,
    reviewAppListOffset,
    applicationsToReviewLoading,
    activeChangeRequests,
    activeChangeReqLogs,
    activeStatusLogs,
    appStatusLogsLoading,
    measureList,
    applicationMeasuresLoading,
    appNotesLoading,
    appFlagsLoading,
    flagLogs,
    configData,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsToReview: appActions.getApplicationsToReview,
  getApplicationsToReviewRetrofit: appActions.getApplicationsToReviewRetrofit,
  getLogs: appActions.getApplicationsStatusLogs,
  loadUserDetails: userActions.getUserDetails,
  setActiveProgram: userActions.setActiveProgram,
  loadAppMeasures: appActions.getAppMeasures,
  getFlagLogs: appActions.getFlagLogs,
  getRetrofitChecklistDocumentByFacilityId: applicationService.getRetrofitChecklistDocumentByFacilityId,
};

export default connect(mapState, actionCreators)(ReviewBoard);
